.App {
  text-align: center;
  background: #ffffff 0% 0%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiInputBase-input {
  font-family: "Nunito Sans", sans-serif;
}

.MuiSwitch-root.sqGraySwitch .MuiSwitch-track {
  background-color: #000;
}

.MuiSwitch-root.sqGraySwitch
  .MuiSwitch-colorSecondary.Mui-checked
  + .MuiSwitch-track {
  background-color: #000;
}

.MuiSwitch-root.sqGraySwitch .MuiSwitch-colorSecondary.Mui-checked {
  color: #0e1c4e;
}

.customRibbon {
  width: 40px;
  height: 36px;
  position: relative;
  background: #fa7e61;
}

.customRibbon:before {
  content: "";
  position: absolute;
  right: 22px;
  border-left: 20px solid white;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.customRibbon:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.pricing-carousel .carousel-root {
  width: 100%
}

.pricing-carousel .carousel-status{
  visibility: hidden;
}

.sitehmbr  {
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;  
}
