.blueHeaderTxt {
  color: #72cac9;
}
.samonHeaderTxt {
  color: #f37c61 !important;
}
.paraBlackText {
  color: #2b3350 !important;
}
.purpleTxt {
  color: #b872af;
}

.captionGray {
  color: #64626a;
}
.captionViolet {
  color: #B872AF;
}
.captionBlack {
  color:#1B1E4C;
}
.roundedBtn {
  color: #ffffff;
  background-color: #72cac9;
  border-radius: 20px;
  padding: 8px 20px;
  font-size: 20px;
  text-transform: capitalize !important;
}

.nunitoFont {
  font-family: "Nunito Sans", sans-serif;
}

.nunitoFontForce {
  font-family: "Nunito Sans", sans-serif !important;
}

.hankrndFont {
  font-family: "sq-hank-rnd", sans-serif !important;
}

.barlowFont {
  font-family: 'Barlow', sans-serif !important;
}

.socialIcon {
  width: 24px;
  height: 24px;
}

.socialIconMobile {
  width: 16px;
  height: 16px;
}

.navyBlueColor {
  color: #0e1c4e;
}

.sqEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.font14  { 
  font-size: 0.875rem !important;
}

.link {
  color: #2B3350 !important;
  text-decoration:none !important;
  font-size: 16px;
}

.link:hover {
  text-decoration: underline !important;
}

.navyBlueColorForce {
  color: #0e1c4e !important;
}

.primaryBtnAccent {
  background-color: #fa7e61 !important;
  color: #0e1c4e !important;
  text-transform: capitalize;
  border: none !important;
}

.primaryBtnAccent:disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.primaryBtnAccent:hover {
  background-color: #fa7e61 !important;
  color: #0e1c4e !important;
}
.sqEllipsisTwoLines {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-all;
}